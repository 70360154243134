<template>
  <div>
    <div class="row">
      <div class="col-11 d-flex justify-content-end" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <base-button slot="footer" type="secondary" fill @click="novo">Nova</base-button>
      </div>
    </div>
    <!-- {{ userdata }} -->
    <template v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <div class="loader"></div>
          </div>
          <h4 style="margin-top:2em; margin-bottom:2em;">Carregando...</h4>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
            <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
              <table id="tbl-guru" class="display compact2">
                <thead>
                  <tr>
                    <th class="sm" v-for="item in columns">
                        <small style="font-weight: bold;">{{item['label']}}</small>
                    </th>  
                    <th>
                        Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in userdata" :key="index">
                      <td v-for="col in columns">
                        <template v-if="col['field']=='is_encerrada'">
                         {{item[col['field']] == 1 ? 'Sim' : 'Não' }}
                        </template>
                        <template v-else>
                          {{ item[col['field']] }}
                        </template>
                      </td>
                      <td>
                         <base-button icon round class="btn-google" @click="view(item['id'])">
                          <i class="tim-icons icon-zoom-split"></i>
                        </base-button>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  // import config from '@/config';
  export default {
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Nome', field: 'nome'},
          { label: 'Calculada', field: 'is_encerrada'},
          { label: 'Vigência', field: 'dh_limite'},
        ],
        userdata: {},
        instancias: [],
        instancia: null,
        tiposGrupos: [],
        campanhas: [],
        grupos: {},
        header: { headers: {"client-token": "F4d8c61457edb472a8433d9a6570da4bfS"}},
        loading: true,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        // primeiraCarga: true,
      }
    },
    methods: {
      novo() {
        this.$router.push('campanha/nova')
      },
      hasGroup(grupoId) {
        return Object.keys(this.grupos).includes(grupoId)
      },
      view(id) {
        this.$router.push('campanha/view/'+id)
      },
      getTipoGrupo(id) {
        for (let i=0; i<this.tiposGrupos.length; i++) {
          if (this.tiposGrupos[i]['id'] == id) {
            return this.tiposGrupos[i]['nome']
          }
        }
        return '-'
      },
      getCampanha(id) {
        for (let i=0; i<this.campanhas.length; i++) {
          if (this.campanhas[i]['id'] == id) {
            return this.campanhas[i]
          }
        }
        return '-'
      },
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      // getCampanhas(){
      //   let camps = []
      //   this.$aws
      //   .get('whatsapp/campanha/')
      //   .then(response => {
      //     if (response.data) {
      //       this.campanhas = response.data
      //     }
      //   })
      //   .catch(function (error) {
      //         console.error(error)
      //   });
      //   // this.campanhas = camps 
      // },
      // getTiposGrupos(){
      //   this.$aws
      //   .get('whatsapp/tipogrupo')
      //   .then(response => {
      //     if (response.data) {
      //       this.tiposGrupos = response.data 
      //     }
      //   })
      //   .catch(function (error) {
      //         console.error(error)
      //   }); 
      // },
      // getGrupos(){
      //   this.$aws
      //   .get('whatsapp/grupo')
      //   .then(response => {
      //     if (response.data) {
      //       this.grupos = response.data 
      //     }
      //   })
      //   .catch(function (error) {
      //         console.error(error)
      //   }); 
      // },
    },
    mounted() {
      let user = this.getUsuario()
      
      // this.getTiposGrupos()
      // this.getCampanhas()

      if (user && user.id) {
        this.$aws
        .get('whatsapp/campanha/')
        .then(response => {
          this.userdata = JSON.parse(JSON.stringify(response.data))
          $(document).ready( function () {
            $('#tbl-guru').DataTable({
              ordering: false,
              lengthMenu: [50, 100, 300, 500],
              searching: true,
              "language": {
                "emptyTable": "Nenhum registro disponível na tabela",
                "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                "lengthMenu":     "Mostrar _MENU_ registros",
                "loadingRecords": "Carregando...",
                "search":         "Busca:",
                "zeroRecords":    "Nenhum registro encontrado.",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                }
              },
            });
          });

          this.loading = false
        })
        .catch(function (error) {
              console.error(error)
        }); 
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  // .select-input {
  //   background-color: #1E1E2C !important; 
  // }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
</style>
