<template>
  <div>
    <div id="myModalErro" class="modal" v-if="showErro">
        <!-- Modal content -->
        <div class="modal-content">
          <span class="close" @click="showErro = false">&times;</span>
          <div class="table-full-width table-responsive" style="overflow-x: hidden;">
            <table class="display compact">
              <thead>
                <tr style="background-color: #1E1E2F;color: white;">
                  <th scope="col" style="padding: 0px 10px;"></th>
                  <th scope="col" style="padding: 0px 10px;">url</th>
                  <th scope="col" style="padding: 0px 10px; text-align:center;">código</th>
                  <th scope="col" style="padding: 0px 10px; text-align:center;">mensagem</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="err, index in erros">
                  <td style="font-size: .8em; text-align: center; width:2%;">{{index+1}}</td>
                  <td style="font-size: .8em; text-align: center; width:60%;">{{err.url}}</td>
                  <td style="text-align: center; width:10%;">{{err.status}}</td>
                  <td style="font-size: .8em; text-align:center; width:30%;">{{err.message}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <div id="myModalWarn" class="modal" v-if="showWarns">
        <!-- Modal content -->
        <div class="modal-content">
          <span class="close" @click="showWarns = false">&times;</span>
          <div class="table-full-width table-responsive" style="overflow-x: hidden;">
            <table class="display compact">
              <thead>
                <tr style="background-color: #1E1E2F;color: white;">
                  <th scope="col" style="padding: 0px 10px;"></th>
                  <th scope="col" style="padding: 0px 10px;">url</th>
                  <th scope="col" style="padding: 0px 10px; text-align:center;">código</th>
                  <th scope="col" style="padding: 0px 10px; text-align:center;">mensagem</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="err, index in warns">
                  <td style="font-size: .8em; text-align: center; width:2%;">{{index+1}}</td>
                  <td style="font-size: .8em; text-align: center; width:60%;">{{err.url}}</td>
                  <td style="text-align: center; width:10%;">{{err.status}}</td>
                  <td style="font-size: .8em; text-align:center; width:30%;">{{err.message}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <div id="myModalImport" class="modal" v-if="showModalImport">
        <!-- Modal content -->
        <div class="modal-content">
          <span class="close d-flex align-self-end" @click="showModalImport = false">&times;</span>
          <div class="d-flex flex-row">
            <div class="col-6 row d-flex flex-column">
              <div class="">
                <div class="tipos-titulo mr-5">
                  <span>Grupos Selecionados</span>
                </div>
              </div>
              <div class="">
                <ul>
                  <li v-for="i in gruposSelecionados">
                    {{ i['nome'] }} ({{i['codigo']}})
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6 row d-flex flex-column">
              <div class="">
                <div class="tipos-titulo mr-5">
                  <span>Tipos Associados</span>
                </div>
              </div>
              <div class="d-flex flex-wrap">  
                <div v-for="grupo in tiposGrupo" :key="grupo.id" >
                  <div class="">
                  {{ grupo.nome }}
                  </div>
                  <div class="">
                    <bootstrap-toggle v-model="tipos[grupo.id]" :options="{ on: 'Sim', off: 'Não' }" :disabled="false" :label="grupo.id"/> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center button-bar">
              <base-button slot="footer" type="secondary" fill @click="showModal = false">Cancelar</base-button>
              <base-button slot="footer" type="primary" fill @click="associarEmLote">Salvar em Lote</base-button>
            </div>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-8 text-center d-flex align-items-start" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <!-- <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#exampleModalLong">
          {{erros.length}} erros
        </button> -->
        <button type="secondary" @click="importarEmLote" style="margin-bottom:0px;" :disabled="gruposSelecionados.length<1" class="btn btn-import-lote">
          Importar em Lote
        </button>
        <button id="myBtnErro" class="btn btn-danger" @click="showErro = true" v-if="erros.length > 0">
          {{erros.length}} erros
        </button>
        <button id="myBtnWarn" class="btn btn-warning" @click="showWarns = true" v-if="warns.length > 0">
          {{warns.length}} warnings
        </button>
      </div>
      <div class="col-4 text-center d-flex align-items-end" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <select v-model="instancia" class="form-control select-input">
          <option v-for="i in instancias" :value="i">{{ i.nome }}</option>
        </select>
        <base-button slot="footer" type="secondary" @click="pesquisar" style="margin-bottom:0px;" :disabled="!instancia" class="btn-pesquisar">
          Pesquisar
        </base-button>
      </div>
    </div>
    <template v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <div class="loader"></div>
          </div>
          <h4 style="margin-top:2em; margin-bottom:2em;">Carregando...</h4>
          <h3 style="margin-top:2em; margin-bottom:2em;" v-if="count_total > 0"> {{ count_atual }} / {{ count_total }}</h3>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12" v-if="userdata.length > 0">
            <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
              <table id="tbl-guru" class="display compact2">
                <thead>
                  <tr>
                    <th>
                      <!-- <input class="" type="checkbox"> -->
                    </th>
                    <th class="sm" v-for="item in columns">
                        <small style="font-weight: bold;">{{item['label']}}</small>
                    </th>  
                    <th>
                      <small style="font-weight: bold;">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in userdata" :key="index">
                      <td>
                        <input type="checkbox" :value="item" v-model="gruposSelecionados" :disabled="hasGroup(item['phone'])">
                      </td>
                      <td v-for="col in columns">
                        <template v-if="col['field']=='imagem'">
                          <img :src="item[col['field']]" style="max-width: 120px;"/>
                        </template>
                        <template v-else-if="col['field']=='datahora_publicacao'">
                          <span v-if="item['status']==1">{{ item[col['field']] }}</span>
                          <span v-else> - x - </span>
                        </template>
                        <template v-else>
                          {{ item[col['field']] }}
                        </template>
                      </td>
                      <td>
                        <base-button icon round class="btn-google" :class="hasGroup(item['phone'])?'btn-success':'btn-primary'" 
                                    @click="showPopUpImport(item)" :disabled="hasGroup(item['phone'])">
                          <template v-if="hasGroup(item['phone'])">
                            <i class="tim-icons icon-check-2"></i>
                          </template>
                          <template v-else>
                            <i class="tim-icons icon-double-right"></i>
                          </template>
                        </base-button>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
        <!-- <div v-else>
          foo
        </div> -->
      </div>
    </template>
  </div>
</template>
<script>
  import config from '@/config';
  export default {
    data() {
      return {
        columns: [
          { label: 'Id', field: 'communityId'},
          { label: 'Nome', field: 'subject'},
          { label: 'Phone', field: 'phone'},
        ],
        userdata: [],
        instancias: [],
        instancia: null,
        // base_url: null,
        grupos: {},
        header: {"headers": { "Client-Token": "F4d8c61457edb472a8433d9a6570da4bfS" }},
        loading: false,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        erros: [],
        showErro: false,
        warns: [],
        showWarns: false,
        count_total: 0,
        count_atual: 0,
        gruposSelecionados: [],
        showModalImport: false,
      }
    },
    methods: {
      addPromiseNotFulfilled(resp){
        if (resp['status'] == 'fulfilled') {
        // if (resp['reason']['response']['status']==400) {
          this.erros.push({
            'url': resp['reason']['config']['url'],
            'status': resp['reason']['response']['status'],
            'message': resp['reason']['response']['data']
          })
        } else {
          this.warns.push({
            'url': resp['reason']['config']['url'],
            'status': resp['reason']['response']['status'],
            'message': resp['reason']['response']['data']
          })
        }
      },
      // importarEmLote(){
      //   let grupos = new Array()
      //   this.gruposSelecionados.forEach(element => {
      //     grupos.push(element.id)
      //   });
      //   let payload = {
      //     'grupos': grupos,
      //     'tipos': this.tipos
      //   }        
      //   this.$aws
      //   .post('whatsapp/grupo/importar/lote', payload)
      //   .then(response => {
      //     this.grupos = JSON.parse(JSON.stringify(response.data))
      //     this.showModal = false
      //     this.$swal("Ok!", "Grupos Salvos com sucesso.", "success");
      //   })
      //   .catch(function (error) {
      //       console.error(error)
      //       this.showModal = false
      //       this.$swal("Ops!", "Ocorreu um erro:  Erro "+ response.status +"", "error");
      //   }); 
      // },
      importarEmLote() {
        let self = this
        let itens = new Array()
        this.gruposSelecionados.forEach(element => {
          itens.push({'nome': element['subject'], 'codigo': element['phone'], 'instancia': this.instancia.id})
        });

        let msg = "Tem certeza que deseja importar os seguintes grupos? <br/><br/><div style=\"text-align:left\"><ul>"
        if (itens && itens.length > 0) {
          itens.forEach(i => {
            msg += "<li><small>" + i['nome'] +"</small></li>"
          })
          msg += "</ul></div>"
       
          this.$swal({
              title: "Importar Grupos em lote",
              html: msg ,
              type: "warning",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Importar"
          }).then((result) => { // <--
              if (result.value) { // <-- if confirmed
                this.$aws
                .post('whatsapp/grupo/importar/lote', {'grupos': itens})
                .then(response => {
                  if (response.status == 200) {
                    this.$swal("Ok!", "Grupos Importados.", "success");
                  } else {
                    this.$swal("Ops!", "Ocorreu um erro:  Erro "+ response.status +"", "error");
                  }
                  this.$router.push('/grupo')
                })
                .catch(function (error) {
                    self.$swal("Ops!", "Ocorreu algum erro inesperado. ("+ error +")", "error");
                    console.error(error)
                });   
              }
          });
        }
      },
      async pesquisar() {
        this.userdata = []
        this.warns = []
        this.erros = []
        this.count_atual = 0
        this.count_total = 0
        this.loading = true
        let url_grupo = this.base_url + '/group-metadata/'
        let self = this
        
        //Consulta dados da instância
        let instance_phone = await this.$http.get(this.base_url+'/device', this.header)
            .then(function(phone_obj){
              return phone_obj.data['phone']
            })
            .catch(function(error){
              self.$swal("Ops!", "Ocorreu um erro de comunicação com a Z-API ao buscar o número da instância: \n\n" + error, "error");
              console.error(error)
            })  

        if  (instance_phone != null) {
          //Consulta os grupos da instancia e buscar informacao de cada grupo
          const promises = await this.$http.get(this.base_url+'/chats?pageSize=10000', this.header)
                            .then(function(chats) {
                              const retorno = []
                              self.count_total = chats.data.length
                              for (let entry of chats.data) {
                                if (entry['isGroup']) {
                                  if (self.instancia['tipo'] == 2 || (self.instancia['tipo'] == 1 && entry['communityId'])) {
                                    retorno.push(self.$http.get(url_grupo+entry['phone'], self.header).finally(function(){
                                      self.count_atual += 1
                                    }))
                                  } 
                                } 
                              }
                              return retorno
                            })
                            .catch(function(error){
                              self.$swal("Ops!", "Ocorreu um erro de comunicação com a Z-API ao buscar os grupos da instância: \n\n" + error, "error");
                              console.error(error)
                            })  
          
          //quando todas as buscas para as informacoes dos grupos tiverem terminado
          try {
            await Promise.allSettled(promises)
                  .then(function(response){
                    response.forEach(function(resp){
                      if (resp['status'] == 'fulfilled') {
                        if (resp.value.status == 200) {
                          let grp = resp.value.data //response.data
                          if (grp['participants']) {
                            for (let usr of grp['participants']) {
                              if (usr['phone'] == instance_phone && (usr['isAdmin'] || usr['isSuperAdmin'])) {
                                self.userdata.push(grp)
                              }
                            }
                          }
                        } else {
                          self.addPromiseNotFulfilled(resp)
                        }
                      } else {
                        self.addPromiseNotFulfilled(resp)
                      }
                    })
                  })
                  .catch(function(error){
                    console.error('# Erro: ' + error)
                  })
                  .finally(function(){
                    $(document).ready( function () {
                      $('#tbl-guru').DataTable({
                        ordering: false,
                        lengthMenu: [50, 100, 300, 500],
                        searching: true,
                        "language": {
                          "emptyTable": "Nenhum registro disponível na tabela",
                          "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                          "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                          "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                          "lengthMenu":     "Mostrar _MENU_ registros",
                          "loadingRecords": "Carregando...",
                          "search":         "Busca:",
                          "zeroRecords":    "Nenhum registro encontrado.",
                          "paginate": {
                              "first":      "Primeiro",
                              "last":       "Último",
                              "next":       "Próximo",
                              "previous":   "Anterior"
                          }
                        },
                      });
                    });
                    self.loading = false
                  }) 
          } catch (error) {
            console.error(error)
          }
        } else {
          this.loading = false
        }
      },
      hasGroup(grupoId) {
        return Object.keys(this.grupos).includes(grupoId)
      },
      salvaGrupo(grupo) {
        this.$aws
        .post('whatsapp/grupo', grupo)
        .then(response => {
          this.grupos = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
              console.error(error)
        }); 
      },
      showPopUpImport(item) {
        let self = this
        let grupo = null
        if (item && item['subject'] && item['phone'] && this.instancia && this.instancia.id) {
          grupo = {'nome': item['subject'], 'codigo': item['phone'], 'instancia': this.instancia.id}
        }
        if (grupo) {
          this.$swal({
              title: "Importar Grupo",
              text: "Tem certeza que deseja importar o grupo '" + item['subject'] + "'?",
              type: "warning",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Importar"
          }).then((result) => { // <--
              if (result.value) { // <-- if confirmed
                this.$aws
                .post('whatsapp/grupo/importar', grupo)
                .then(response => {
                  if (response.status == 200) {
                    this.$swal("Ok!", "Grupo Importado.", "success");
                  } else {
                    this.$swal("Ops!", "Ocorreu um erro:  Erro "+ response.status +"", "error");
                  }
                  this.$router.push('/grupo')
                })
                .catch(function (error) {
                    self.$swal("Ops!", "Ocorreu algum erro inesperado. ("+ error +")", "error");
                    console.error(error)
                });   
              }
          });
        }
      },
      edit(id) {
        this.$router.push('noticias/edit/'+id)
      },
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
    },
    watch: {
      instancia: function(newValue, oldValue){
        this.base_url = 'https://api.z-api.io/instances/' + newValue.instance_id + '/token/' + newValue.token
      }
    },
    mounted() {
      this.$aws
      .get('whatsapp/instancia')
      .then(response => {
        this.instancias = JSON.parse(JSON.stringify(response.data))
      })
      .catch(function (error) {
            console.error(error)
      }); 
      this.$aws
      .get('whatsapp/grupo')
      .then(response => {
        this.grupos = JSON.parse(JSON.stringify(response.data))
      })
      .catch(function (error) {
            console.error(error)
      }); 
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  // .select-input {
  //   background-color: #1E1E2C !important; 
  // }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}


 /* The Modal (background) */
 .modal {
  // display: none; /* Hidden by default */
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 

.btn-pesquisar:disabled {
  color: white;
}
</style>
