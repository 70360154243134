<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <base-button slot="footer" type="secondary" fill @click="nova">Nova Notícia</base-button>
      </div>
    </div>
    <template v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <div class="loader"></div>
          </div>
          <h4 style="margin-top:2em; margin-bottom:2em;">Carregando...</h4>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
            <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
              <table id="tbl-guru" class="display compact">
                <thead>
                  <tr>
                    <th class="sm" v-for="item in columns">
                        <small style="font-weight: bold;">{{item['label']}}</small>
                    </th>  
                    <th>
                        Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in userdata" :key="index">
                      <td>
                        {{ item['id'] }}
                      </td>
                      <td>
                        {{ item['titulo'] }}
                      </td>
                      <td v-html="item['texto']">
                      </td>
                      <td>
                        <img :src="item['imagem']" style="max-width: 120px;"/>
                      </td>
                      <td>
                        <template v-if="item['datahora_publicacao']">
                          <span v-if="item['status']==1">{{ item['datahora_publicacao'] }}</span>
                          <span v-else> - x - </span>
                        </template>
                      </td>
                      <td>
                        <base-button icon round class="btn-google" @click="edit(item['id'])">
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                        <base-button icon round class="btn-google" @click="showPopUpDelete(item['id'])">
                          <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                      
                        <!-- [x] [{{ item['status'] }}] [x] -->
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </template>
    
    <!-- <div class="row">
      <div class="col-12">
        <vue-good-table :columns="columns" :rows="userdata" :search-options="{enabled: true, placeholder: 'Buscar...'}" />
      </div>
    </div> -->
  </div>
</template>
<script>
  import config from '@/config';
  

  // const MONTHS = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ]


  export default {
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        loading: true,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
      }
    },
    methods: {
      nova() {
        this.$router.push('/noticias/novo')
      },
      showPopUpDelete(id) {
        // this.$swal("Ops!", "Ocorreu algum erro inesperado.", "error");
        this.$swal({
            title: "Deletar Notícia",
            text: "Tem certeza que deseja deletar a notícia '"+id+"'?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Sim, Excluir!"
        }).then((result) => { // <--
            if (result.value) { // <-- if confirmed
              this.$aws
              .delete('noticias/'+id)
              .then(response => {
                if (response.status == 200) {
                  this.$swal("Ok!", "Notícia Excluída.", "success");
                } else {
                  this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
                }
                this.$router.push('/noticias/#'+id)
              })
              .catch(function (error) {
                    console.error(error)
              });   
            }
        });
      },
      edit(id) {
        this.$router.push('noticias/edit/'+id)
      },
      getUsuario() {
        try {
          let user = null
          if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
            user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          }
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
    },
    mounted() {
      let user = this.getUsuario()
      if (user && user.id) {
        this.$aws
        .get('noticias')
        .then(response => {
          this.userdata = JSON.parse(JSON.stringify(response.data))

          $(document).ready( function () {
            $('#tbl-guru').DataTable({
              ordering: false,
              lengthMenu: [50, 100, 300, 500],
              searching: true,
              "language": {
                "emptyTable": "Nenhum registro disponível na tabela",
                "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                "lengthMenu":     "Mostrar _MENU_ registros",
                "loadingRecords": "Carregando...",
                "search":         "Busca:",
                "zeroRecords":    "Nenhum registro encontrado.",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                }
              },
            });
          });
          this.loading = false
        })
        .catch(function (error) {
          console.error(error)
        });   
      }


    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
</style>
