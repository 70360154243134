<template>
  <div>
    <div id="myModal" class="modal" v-if="showModal">
        <!-- Modal content -->
        <div class="modal-content">
          <span class="close d-flex align-self-end" @click="showModal = false">&times;</span>
          <div class="d-flex flex-row">
            <div class="col-6 row d-flex flex-column">
              <div class="">
                <div class="tipos-titulo mr-5">
                  <span>Grupos Selecionados</span>
                </div>
              </div>
              <div class="">
                <ul>
                  <li v-for="i in gruposSelecionados">
                    {{ i['nome'] }} ({{i['codigo']}})
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6 row d-flex flex-column">
              <div class="">
                <div class="tipos-titulo mr-5">
                  <span>Tipos Associados</span>
                </div>
              </div>
              <div class="d-flex flex-wrap">  
                <div v-for="grupo in tiposGrupo" :key="grupo.id" >
                  <div class="">
                  {{ grupo.nome }}
                  </div>
                  <div class="">
                    <bootstrap-toggle v-model="tipos[grupo.id]" :options="{ on: 'Sim', off: 'Não' }" :disabled="false" :label="grupo.id"/> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center button-bar">
              <base-button slot="footer" type="secondary" fill @click="showModal = false">Cancelar</base-button>
              <base-button slot="footer" type="primary" fill @click="associarEmLote">Salvar em Lote</base-button>
            </div>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-8" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <button type="secondary" @click="showModal = true" style="margin-bottom:0px;" :disabled="gruposSelecionados.length<1" class="btn btn-import-lote">
          Associação em Lote
        </button>
      </div>
      <div class="col-4 d-flex justify-content-end" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <base-button slot="footer" type="secondary" fill @click="importar">Importar</base-button>
      </div>
    </div>
    <!-- {{ userdata }} -->
    <template v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <div class="loader"></div>
          </div>
          <h4 style="margin-top:2em; margin-bottom:2em;">Carregando...</h4>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
            <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
              <table id="tbl-guru" class="display table">
                <thead>
                  <tr>
                    <th>
                      <!-- <input class="" type="checkbox"> -->
                    </th>
                    <th class="sm" v-for="item in columns">
                        <small style="font-weight: bold;">{{item['label']}}</small>
                    </th>  
                    <th>
                        Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in userdata" :key="index">
                      <td>
                        <input type="checkbox" :value="item" v-model="gruposSelecionados"> 
                      </td>
                      <td v-for="col in columns">
                        <template v-if="col['field']=='ativo'">
                         {{ item[col['field']] == 1 ? 'Sim' : 'Não'  }}
                        </template>
                        <template v-else>
                          {{ item[col['field']] }}
                        </template>
                      </td>
                      <td>
                         <base-button icon round class="btn-google" @click="edit(item['id'])">
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import config from '@/config';
  import BootstrapToggle from 'vue-bootstrap-toggle'
  export default {
    components: { BootstrapToggle },
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Nome', field: 'nome'},
          { label: 'Phone', field: 'codigo'},
          { label: 'Ativo', field: 'ativo'},
        ],
        userdata: {},
        instancias: [],
        instancia: null,
        // base_url: null,
        grupos: {},
        header: { headers: {"client-token": "F4d8c61457edb472a8433d9a6570da4bfS"}},
        loading: true,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        gruposSelecionados: [],
        
        showModal: false,
        tipos: {},
        // primeiraCarga: true,
      }
    },
    methods: {
      importar() {
        this.$router.push('grupo/importar')
      },
      hasGroup(grupoId) {
        return Object.keys(this.grupos).includes(grupoId)
      },
      edit(id) {
        this.$router.push('grupo/edit/'+id)
      },
      associarEmLote() {
        let grupos = new Array()
        this.gruposSelecionados.forEach(element => {
          grupos.push(element.id)
        });
        let payload = {
          'grupos': grupos,
          'tipos': this.tipos
        }        
        this.$aws
        .post('whatsapp/grupo/lote', payload)
        .then(response => {
          this.grupos = JSON.parse(JSON.stringify(response.data))
          this.showModal = false
          this.$swal("Ok!", "Grupos Salvos com sucesso.", "success");
        })
        .catch(function (error) {
            console.error(error)
            this.showModal = false
            this.$swal("Ops!", "Ocorreu um erro:  Erro "+ response.status +"", "error");
        }); 
      },
      check(id) {
        console.log(id)
         if (this.gruposSelecionados.includes(id)) {
          const index = this.gruposSelecionados.indexOf(id);  
          if (index > -1) { // only splice array when item is found
            this.gruposSelecionados.splice(index, 1); // 2nd parameter means remove one item only
          }
         } else {
            this.gruposSelecionados.append(id)
         }
      },
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
    },

    mounted() {
      this.$aws
      .get('whatsapp/tipogrupo')
      .then(response => {
        if (response.data) {
          this.tiposGrupo = JSON.parse(JSON.stringify(response.data))
        } 
      })
      .catch(function (error) {
            console.error(error)
      });   

      this.$aws
      .get('whatsapp/grupo')
      .then(response => {
        this.userdata = JSON.parse(JSON.stringify(response.data))

          $(document).ready( function () {
            $('#tbl-guru').DataTable({
              select: {
                  info: true,
                  style: 'multi',
                  selector: 'td'
              },
              ordering: false,
              lengthMenu: [50, 100, 300, 500],
              searching: true,
             
              // processing: true,
              // 'checkboxes':{
              //     'selectRow':true
              // },
              "language": {
                "emptyTable": "Nenhum registro disponível na tabela",
                "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
                "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
                "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                "lengthMenu":     "Mostrar _MENU_ registros",
                "loadingRecords": "Carregando...",
                "search":         "Busca:",
                "zeroRecords":    "Nenhum registro encontrado.",
                "paginate": {
                    "first":      "Primeiro",
                    "last":       "Último",
                    "next":       "Próximo",
                    "previous":   "Anterior"
                }
              },
            });
          });

        this.loading = false
     
      })
      .catch(function (error) {
            console.error(error)
      }); 
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  // .select-input {
  //   background-color: #1E1E2C !important; 
  // }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
/* The Modal (background) */
.modal {
  // display: none; /* Hidden by default */
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 

.tipos-titulo {
  border-bottom: 2px solid #2b3553;
  margin-bottom: 1em;
  margin-top: 2em;
}
.button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
</style>
