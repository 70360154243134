<template>
  <div>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 background-color="#000000">
                 <div style="padding: 5em; background-color: white; border-radius: 5%;">
                  <div class="d-flex justify-content-center">
                      <div class="loader"></div>
                  </div>
                  <br/>
                  <div>
                      <p v-if="isSendingMsg">Aguarde: <span class="load-perc"> Enviando Enquete...</span> </p>
                  </div>
                  </div>
        </loading>
    </div>
    <div class="row">
      <div class="col-md-8 mb-1 mt-3">
        <base-button class="btn btn-sm" :class="{'btn-primary': tipo==1}" fill @click="tipo=1">Por Tipo</base-button>
        <base-button class="btn btn-sm" :class="{'btn-primary': tipo==2}" fill @click="tipo=2">Avulso</base-button>
      </div>
    </div>
    <div class="row" v-if="tipo==1" style="margin-top:-10px;">
      <div class="col-md-8">
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;" class="pb-2">
          <h4>Tipo:</h4>
          <select v-model="selectedTipoGrupo" class="form-control select-input">
            <option v-for="i in tiposGrupos" :value="i">{{ i.nome }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-if="tipo==2" style="margin-top:-10px;">
      <div class="col-md-8" >
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;">
          <p>Selecione os grupos destinatários:</p>
          <div class="d-flex flex-wrap">
            <template v-for="grp in Object.values(grupos)">
              <div class="col-md-4">
                <input type="checkbox" :id="'grupos_'+grp.id" name="grupos"  v-model="gruposSelecionados" :value="grp.id"/> &nbsp;
                <label :for="'grupos_'+grp.id" class="grupo-nome">{{ grp.nome }}</label>
              </div>
            </template>
          </div>
          <br />
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-8">
        <h4>Texto da enquete</h4>
        <vue-editor v-model="mensagem" :editorToolbar="customToolbar" :init="{height: 500}"></vue-editor>
      </div>
    </div>
    <br/>
    <div class="row mt-1 mb-1" style="margin-top:-10px;">
      <div class="col-md-8 ">
        <div style="border-bottom:2px solid #F9B9A9; border-top:2px solid #F9B9A9;" class="pt-2 pb-3">
          <h4>Campanha:</h4>
          <select v-model="selectedCampanha" class="form-control select-input">
            <option value="null">Nenhuma</option>
            <option v-for="c in campanhas" :value="c">{{ c.nome }}</option>
          </select>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <h4>Opções</h4>
        <table>
          <tr>
            <th>Nome</th>
            <th v-if="selectedCampanha">Valor</th>
            <th colspan="2"></th>
          </tr>
          <tr v-for="i in opcoes.length">
            <td style="height: 40px;">
              <input type="text" name="pollOptionName" v-model="opcoes[i-1].nome" />
            </td>
            <td v-if="selectedCampanha">
              <input type="text" name="pollOptionVaue" v-model="opcoes[i-1].valor" class="ml-1" />
            </td>
            <td>
              <button class="btn btn-sm ml-1" @click="del(i)" v-if="i>2"><i class="fa fa-minus"></i></button>
            </td>
            <td>
              <button class="btn btn-sm ml-1" @click="add" v-if="i==opcoes.length"><i class="fa fa-plus"></i></button>
            </td>
            <br/> 
          </tr>
        </table>
      </div>
      <div class="col-2 d-flex flex-column options-panel">
        <div>
          Data/hora Término:
          <date-picker v-model="model.dh_limite" type="datetime" value-type="YYYY-MM-DD HH:mm:ss"></date-picker>
        </div>
        
        <div class="mt-3">
          Máx. de Respostas Permitidas: &nbsp; 
          <button type="button" class="btn btn-xs btn-info" @click="infoMaxResp()">
            <span class="fa fa-info"></span>
          </button>
          <br/>
          <input type="number" min="1" :max="opcoes.length" v-model="maxRespostas" style="width: 6em;" disabled/>
        </div>      
          
        <div class="mt-3">
            Valor de participação: &nbsp;
            <button type="button" class="btn btn-xs btn-info" @click="infoValorParticipacao()">
              <span class="fa fa-info"></span>
            </button>
            <br/>
            <input type="number" min="1" v-model="valorParticipacao" style="width: 6em;" disabled/>
        </div>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-8 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Cancelar</base-button>
        <base-button slot="footer" type="primary" fill @click="salvar" :disabled="isEnviarDisabled()">Enviar</base-button>
      </div>
    </div>
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { VueEditor } from 'vue2-quill-editor'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { BootstrapToggle, Loading, VueEditor, DatePicker },
    data() {
      return {
        // columns: [
        //   { label: 'Id', field: 'id'},
        //   { label: 'Título', field: 'titulo'},
        //   { label: 'Texto', field: 'texto'},
        //   { label: 'Imagem', field: 'imagem'},
        //   { label: 'Publicação', field: 'datahora_publicacao'},
        // ],
        // userdata: {},
        model: {},
        // publicado: false,
        tipo: 1,
        mensagem: '',
        // id: this.$route.params.id,
        tiposGrupos: [],
        selectedTipoGrupo: null,
        grupos: {},
        gruposSelecionados: [],
        campanhas: [],
        selectedCampanha: null,
        // searchOptions: {
        //       enabled: true,
        //       placeholder: 'Buscar...',
        // },
        isLoading: false,
        // isSendingFile: false,
        isSendingMsg: false,
        marcarUsuarios: false,
        maxRespostas: 1,
        valorParticipacao: 100,
        // upload_perc: 0,
        // BUCKET_NAME:  'https://guru-admin-files.s3.amazonaws.com/',
        customToolbar: ['bold','italic'],
        opcoes: [{'nome':'', 'valor':0}, {'nome':'', 'valor':0}, {'nome':'', 'valor':0}, {'nome':'', 'valor':0}]

      }
    },
    // watch: {
    //   publicado: function(newValue, oldValue){
    //     if (newValue) {
    //       this.model.status = 1
    //     } else {
    //       this.model.status = 0
    //     }
    //   }
    // },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      getCampanhas(){
        let camps = []
        this.$aws
        .get('whatsapp/campanha/')
        .then(response => {
          if (response.data) {
            for (let c of response.data) {
              if (!c['is_encerrada']) {
                camps.push(c)
              }
            }
          }
        })
        .catch(function (error) {
              console.error(error)
        });
        this.campanhas = camps 
      },
      getTiposGrupos(){
        this.$aws
        .get('whatsapp/tipogrupo')
        .then(response => {
          if (response.data) {
            this.tiposGrupos = response.data 
          }
        })
        .catch(function (error) {
              console.error(error)
        }); 
      },
      getGrupos(){
        this.$aws
        .get('whatsapp/grupo')
        .then(response => {
          if (response.data) {
            this.grupos = response.data 
          }
        })
        .catch(function (error) {
              console.error(error)
        }); 
      },
      infoMaxResp(){
        this.$swal("Máx. de Respostas Permitidas", "Define a quantidade máxima de respostas que o usuário poderá escolher na enquete.", "info");
      },
      infoValorParticipacao(){
        this.$swal("Valor de participação", "Define um valor que será concedido ao usuário que participar votando, caso o resultado apurado não esteja contemplado em nenhuma das alternativas da enquete.", "info");
      },
      back() {
        this.$router.push('/enquete')
      },
      salvar() {
        var vm = this
        this.model.message = this.tratarTexto(this.mensagem)
        this.model.pollMaxOptions = this.maxRespostas
        this.model.valorParticipacao = this.valorParticipacao
        if (this.selectedCampanha) {
          this.model.campanha = this.selectedCampanha.id
        }

        if (this.tipo == 1) {
          this.model.tipo = this.selectedTipoGrupo['id']
        } else {
          this.model.grupos = this.gruposSelecionados
        }

        this.model.poll = []
        if (this.selectedCampanha) {
          for(let o of this.opcoes) {
            if (o.nome != '') {
              if (o.valor == ''){
                this.$swal("Ops!", "A opção '"+o.nome+"' não possui nenhum valor", "warning");
                return
              } else if (isNaN(Number(o.valor))){
                let index = this.opcoes.indexOf(o)
                this.$swal("Ops!", "Há valor não numérico nas opção '"+o.nome+"':  '"+o.valor+"'", "warning");
                return
              } else {
                this.model.poll.push(o)
              }
            }
          }
        }

        // console.log('model:')
        // console.log(this.model)
        
        vm.isLoading = true
        vm.isSendingMsg = true
        
        this.$aws
          .post('whatsapp/enquete', this.model)
          .then(response => {
            // console.log('# response:')
            // console.log(response)
            if (response.status == 200) {
              this.$swal("Ok!", "Enquete Enviada.", "success");
              this.$router.push('/enquete')
            } else {
              vm.isLoading = false
              this.$swal("Ops!", "Ocorreu algum erro inesperado: (erro"+ response.status +") <br/><br/>" + error.response.data, "error");
            }
          })
          .catch((error) => {
            console.error(error)  
            vm.isLoading = false
            let err_msg = JSON.stringify(error, Object.getOwnPropertyNames(error))
            // let foo = error?.response?.data
            // console.log("err_msg:")
            // console.log(foo);
            vm.$swal("Ops!", "Ocorreu algum erro inesperado no envio da enquete: <br/><br/>" + err_msg, "error");
          });   
      },
      getNome(nome) {
        const date = new Date()
        return date.getFullYear().toString() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2) + '_'
                + ('0' + (date.getHours()+1)).slice(-2) + ('0' + (date.getMinutes()+1)).slice(-2) + '_'
                + nome
      },
      tratarTexto(textoOriginal) {
        let retorno = this.decode(textoOriginal)
        retorno = retorno.replaceAll('<p>','')
        retorno = retorno.replaceAll('</p>','\n')
        retorno = retorno.replaceAll('<strong>','*')
        retorno = retorno.replaceAll('</strong>','*')
        retorno = retorno.replaceAll('<em>','_')
        retorno = retorno.replaceAll('</em>','_')
        retorno = retorno.replaceAll('<br />','\n')
        retorno = retorno.replaceAll(/<.*?>/gm, '')
        return retorno
      },
      decode(encodedString) {
          var textArea = document.createElement('textarea');
          textArea.innerHTML = encodedString;
          return textArea.value;
      },
      // limpar() {
      //   this.imageSrc = null;
      //   document.querySelector('input[type=file]').value = '';
      // },
      // selectMidia() {    
      //   this.imageSrc = document.querySelector('input[type=file]').files[0]
      // },
      isEnviarDisabled() {
        if (!this.model.dh_limite) {
          return true
        }
        if (this.tipo == 1) {
          return !this.selectedTipoGrupo 
        } else {
          return this.gruposSelecionados.length == 0
        }
      },
      add() {
        this.opcoes.push('')
      },
      del(i) {
        let index = i-1
        this.opcoes.splice(index, 1)
      }
    },
    mounted() {
      this.getTiposGrupos()
      this.getGrupos()
      this.getCampanhas()
      this.getUsuario()
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .load-perc {
    color: #FC7D59;
    font-size: 1em;
    // position: fixed;
    // left: 49.3%;
    margin-top: 1em;
    text-align: center;
  }
  .grupo-nome {
    font-size: .7em;
  }
  .poll-option {
    margin-bottom: 1em;
    // min-height: 120px;
    border: 1px solid red;
    // vertical-align: middle;
    // display: table-cell;
  }
  .options-panel {
    background-color: #e8e9ef;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  #quill-container {
    height: 10em;
  }
   /* HTML: <div class="loader"></div> */
  .loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}
</style>
