<template>
  <div>
    <div class="row">
      <div class="col-8" style="border-top: 2px solid #FC7D59; padding-top:2em;">
        <base-input label="Titulo" placeholder="Título" v-model="model.titulo"></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <base-input>
          <label>Texto</label>
          <vue-editor v-model="model.texto" :editorToolbar="customToolbar"></vue-editor>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <base-input label="Imagem" placeholder="http://gurudocartola.com/../imagem.jpg" v-model="model.imagem"></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-8 text-right">
        <bootstrap-toggle v-model="publicado" :options="{ on: 'Publicado', off: 'Em Edição' }" :disabled="false" label="Publicado"/>
      </div>
    </div>
    <div class="row">
      <div class="col-8 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Cancelar</base-button>
        <base-button slot="footer" type="primary" fill @click="salvar">Salvar</base-button>
      </div>
    </div>

    
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'
  // import Editor from '@tinymce/tinymce-vue'
  import { VueEditor } from 'vue2-quill-editor'

  export default {
    components: { BootstrapToggle, VueEditor},
    data() {
      // const id = $route.params.id;
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        model: {},
        publicado: false,
        id: this.$route.params.id,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        customToolbar:[
          ['bold','italic', 'underline', 'strike'],
          ['blockquote','link'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
          [{ 'align': [] }],
        ]
      }
    },
    watch: {
      publicado: function(newValue, oldValue){
        if (newValue) {
          this.model.status = 1
        } else {
          this.model.status = 0
        }
      }
    },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      back() {
        this.$router.push('/noticias')
      },
      salvar() {
        this.model.usr_pub = this.getUsuario()['id']
        if (this.id) {
          this.$aws
            .post('noticias/'+this.id, this.model)
            .then(response => {
              if (response.status == 200) {
                this.$swal("Ok!", "Notícia Salva.", "success");
                this.$router.push('/noticias')
              } else {
                this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
              }
            })
            .catch(function (error) {
                  console.error(error)
            });   
        } else {
          this.$aws
            .post('noticias', this.model)
            .then(response => {
              if (response.status == 200) {
                this.$swal("Ok!", "Notícia Salva.", "success");
                this.$router.push('/noticias')
              } else {
                this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
              }
            })
            .catch(function (error) {
                  console.error(error)
            });   
        }
      }
    },
    mounted() {
      let user = this.getUsuario()
      if (user && user.id) {
        if (this.id) {
          this.$aws
          .get('noticias/'+this.id)
          .then(response => {
            if (response.data) {
              this.model = JSON.parse(JSON.stringify(response.data))
              this.publicado = this.model.status == 1
            } else {
              this.model = {}
            }
          })
          .catch(function (error) {
                console.error(error)
          });   
        }
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
</style>
