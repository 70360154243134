<template>
  <div>
    <template v-if="loading">
      <div class="row">
        <div class="col-12 text-center">
          <div class="d-flex justify-content-center">
            <div class="loader"></div>
          </div>
          <h4 style="margin-top:2em; margin-bottom:2em;">Carregando...</h4>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row d-flex align-items-end">
        <div class="col-4 align-items-end">
          <base-input label="Nome" placeholder="Nome do Grupo" v-model="model.nome"></base-input>
        </div>        
        <div class="col-3 align-items-end">
          <base-input label="Código" placeholder="Código do Grupo" v-model="model.codigo" disabled></base-input>
        </div>        
        <div class="col-1 align-items-end">
          <div class="form-group">
            <bootstrap-toggle v-model="model.ativo" :options="{ on: 'Ativo', off: 'Inativo' }" :disabled="false" /> 
          </div>
        </div>
      </div>
      <div class="row col-8">
        <div class="tipos-titulo w-100">
          <span>Tipos Associados</span>
        </div>
      </div>
      <div class="row col-8">
        <!-- <div class="col-8"> -->
          <div v-for="grupo in tiposGrupo" :key="grupo.id" >
            <div class="col-2 text-left">
            {{ grupo.nome }}
            </div>
            <div class="col-2 text-left">
              <bootstrap-toggle v-model="tipos[grupo.id]" :options="{ on: 'Sim', off: 'Não' }" :disabled="false" :label="grupo.id"/> 
            </div>
          </div>
        <!-- </div> -->
      </div>
      <div class="row">
        <div class="col-8 text-center button-bar">
          <base-button slot="footer" type="secondary" fill @click="back">Cancelar</base-button>
          <base-button slot="footer" type="primary" fill @click="salvar" :disabled="!id">Salvar</base-button>
        </div>
      </div>
  </template>
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'


  export default {
    components: { BootstrapToggle },
    data() {
      // const id = $route.params.id;
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        model: {},
        tiposGrupo: [],
        tipos: {},
        // loading: true,
        loading_tipogrupo: true,
        loading_grupo: true,
        publicado: false,
        id: this.$route.params.id,
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
      }
    },
    // watch: {
    //   publicado: function(newValue, oldValue){
    //     if (newValue) {
    //       this.model.status = 1
  //     } else {
    //       this.model.status = 0
    //     }
    //   }
    // },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      back() {
        this.$router.push('/grupo')
      },
      salvar() {
        this.model.tipos = this.tipos
        // console.log('model:')
        // console.log(JSON.stringify(this.model))
        if (this.id) {
          this.$aws
            .post('whatsapp/grupo/'+this.id, this.model)
            .then(response => {
              if (response.status == 200) {
                this.$swal("Ok!", "Grupo Salvo.", "success");
                this.$router.push('/grupo')
              } else {
                this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
              }
            })
            .catch(function (error) {
                  console.error(error)
            });   
        } 
      }
    },
    computed: {
      loading() {
        return this.loading_grupo || this.loading_tipogrupo;
      }
    },
    mounted() {
      this.$aws
      .get('whatsapp/tipogrupo')
      .then(response => {
        if (response.data) {
          this.tiposGrupo = JSON.parse(JSON.stringify(response.data))
          this.loading_tipogrupo = false
        } 
      })
      .catch(function (error) {
            console.error(error)
      });   

      let user = this.getUsuario()
      if (user && user.id) {
        if (this.id) {
          this.$aws
          .get('whatsapp/grupo/'+this.id)
          .then(response => {
            if (response.data) {
              this.model = response.data
              let mapa = {}
              for (var idx in this.tiposGrupo){
                if (this.model['tipos'].includes(this.tiposGrupo[idx]['id'])) {
                  mapa[this.tiposGrupo[idx]['id']] = true
                } else {
                  mapa[this.tiposGrupo[idx]['id']] = false
                }
              }
              this.tipos = mapa
              this.loading_grupo = false
            } 
          })
          .catch(function (error) {
                console.error(error)
          });   
        }
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipos-titulo {
    border-bottom: 2px solid #2b3553;
    margin-bottom: 1em;
    margin-top: 2em;
  }
</style>
