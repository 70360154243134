<template>
  <div>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 background-color="#000000">
                <div style="padding: 5em; background-color: white; border-radius: 5%;">
                  <div class="d-flex justify-content-center">
                      <div class="loader"></div>
                  </div>
                </div>
        </loading>
    </div>
    <br />
    <div class="row">
      <div class="col-8">
        <h4>Nome</h4>
        <input type="text" name="campanha-name" v-model="model.nome" disabled/>
      </div>
    </div>
    <!-- <br/>
    <div class="row">
      <div class="col-8">
        <h4>Vigência</h4>
        <date-picker v-model="model.dh_limite" type="datetime" value-type="YYYY-MM-DD HH:mm:ss" disabled></date-picker>
      </div>
    </div> -->
    <br/>
    <div class="row">
      <div class="col-8  button-bar">
        <!-- <span class="calculo">Resultado calculado em: <b> {{ model.dh_encerramento }}</b></span>
        <br/><br/> -->
        <table class="table">
          <tr>
            <th>Posição</th>
            <th>Telefone</th>
            <th>Nome</th>
            <th>Pontos</th>
          </tr>
          <tr v-for="r,i in ranking">
            <td>{{i+1}}</td>
            <td>{{ r.participant_phone }}</td>
            <td>{{ r.participant_name }}</td>
            <td>{{ r.pontuacao }}</td>
          </tr>
        </table>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-8 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Voltar</base-button>
        <!-- <base-button slot="footer" type="primary" fill @click="calcular"> {{ model.is_encerrada==0 ? 'Encerrar' : 'Recalcular' }}</base-button> -->
      </div>
    </div>
  </div>
</template>
<script>
  import config from '@/config';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { Loading, DatePicker  },
    data() {
      return {
        id: this.$route.params.id,
        model: {},
        ranking: [],
        isLoading: true,
      }
    },
    methods: {
      back() {
        this.$router.push('/campanha')
      },
      calcular() {
        var vm = this
       
        vm.isLoading = true

        this.$aws
        .post('whatsapp/campanha/ranking/'+this.id)
        .then(response => {
          vm.ranking = response.data
          vm.isLoading = false
        })
        .catch((error) => {
          console.error(error)  
          vm.isLoading = false
          vm.$swal("Ops!", "Ocorreu algum erro ao salvar a campanha: \n" + error, "error");
        });   
      },
      isEnviarDisabled() {
        if (this.model.nome != '') {
          return false
        } else {
          return true
        }
      },

    },
    mounted() {
      this.isLoading = true
      var vm = this
      
      this.$aws
      .get('whatsapp/campanha/'+this.id)
      .then(response => {
        vm.model = response.data
        this.$aws
        .get('whatsapp/campanha/ranking/'+this.id)
        .then(response => {
          vm.ranking = response.data
          vm.isLoading = false
        })
        .catch((error) => {
          console.error(error)  
          vm.isLoading = false
          vm.$swal("Ops!", "Ocorreu algum erro ao salvar a campanha: \n" + error, "error");
        }); 
      })
      .catch((error) => {
        console.error(error)  
        vm.isLoading = false
        vm.$swal("Ops!", "Ocorreu algum erro ao salvar a campanha: \n" + error, "error");
      }); 
},
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .calculo {
    font-size: .8em;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .load-perc {
    color: #FC7D59;
    font-size: 1em;
    // position: fixed;
    // left: 49.3%;
    margin-top: 1em;
    text-align: center;
  }
  .grupo-nome {
    font-size: .7em;
  }
  .poll-option {
    margin-bottom: 1em;
    // min-height: 120px;
    border: 1px solid red;
    // vertical-align: middle;
    // display: table-cell;
  }
  .options-panel {
    background-color: #e8e9ef;
    padding-top: 2em;
  }
  #quill-container {
    height: 10em;
  }
   /* HTML: <div class="loader"></div> */
  .loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}
</style>
