<template>
  <div>
    <div class="row">
      <div class="col-md-8" v-if="mensagem.tipo_grupo_id">
        <p>Tipo:</p>
        <input type="text"class="form-control" :value="getTipoGrupo(mensagem.tipo_grupo_id)" disabled/>
      </div>
      <div class="col-md-8" v-else>
        <p>Grupos:</p>
        <ul class="ul-grp">
          <li v-for="grp in grupos">{{grp['nome']}}</li>
        </ul>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <p>Mídia</p>
        <input type="text" v-model="mensagem.midia" class="form-control" disabled>
      </div>
      <div class="col-md-4">
        <p>Data/hora Cadastro:</p>
        <input type="text" v-model="mensagem.dh_inclusao" class="form-control" disabled>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-8">
        <p>Texto</p>
        <vue-editor v-model="mensagem.mensagem" :editorToolbar="customToolbar"></vue-editor>
      </div>
      <div class="col-md-2 d-flex flex-column justify-content-center">
        <!-- <base-button slot="footer" type="light" fill @click="editar" :disabled="isExpired()">Alterar</base-button> -->
        <base-button slot="footer" type="danger" fill @click="excluir" :disabled="isDeleted()">Excluir</base-button>
      </div>
    </div>
    <br />
    <div class="row" v-if="isDeleted()">
      <div class="col-8 pt-2 pb-2 text-center" style="background-image: linear-gradient(to bottom left, #fd5d93, #ec250d, #fd5d93) !important; color:white;">
          A T E N Ç Ã O : Essa mensagem foi excluída.
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <p>Envios</p>
        <div class="table-full-width table-responsive" style="overflow-y: hidden; overflow-x: hidden;">
          <table id="tbl-guru" class="display compact">
            <thead>
              <tr>
                <th class="sm">
                  <small style="font-weight: bold;">Nome Grupo</small>
                </th> 
                <th class="sm">
                  <small style="font-weight: bold;">Instância</small>
                </th> 
                <th class="sm">
                  <small style="font-weight: bold;">Status</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Zaap Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Message Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Zapi Id</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Operação</small>
                </th>
                <th class="sm">
                  <small style="font-weight: bold;">Envio</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in mensagem.envios" :key="index">
                  <td>
                      {{ grupos[item['grupo_id']]['nome'] }}
                  </td>
                  <td>
                      {{ instancias[grupos[item['grupo_id']]['instancia_id']]['nome'] }}
                  </td>
                  <td>
                      {{ item['status'] }}
                  </td>
                  <td>
                      {{ item['zapi_zaap_id'] }}
                  </td>
                  <td>
                      {{ item['zapi_message_id'] }}
                  </td>
                  <td>
                      {{ item['zapi_id'] }}
                  </td>
                  <td>
                      {{ item['operacao'] }}
                  </td>
                  <td>
                      {{ item['dh_envio'] }}
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center button-bar">
        <base-button slot="footer" type="secondary" fill @click="back">Voltar</base-button>
      </div>
    </div>
  </div>
</template>
<script>
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import config from '@/config';
  import { useRoute } from 'vue-router'
  import { VueEditor } from 'vue2-quill-editor'

  export default {
    components: { BootstrapToggle, VueEditor },
    data() {
      // const id = $route.params.id;
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        // model: {},
        publicado: false,
        id: this.$route.params.id,
        tiposGrupos: [],
        mensagem: {},
        grupos: [],
        instancias: [],
        tipoGrupo: [],
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
        customToolbar: ['bold','italic', 'underline']
      }
    },
    // watch: {
    //   publicado: function(newValue, oldValue){
    //     if (newValue) {
    //       this.model.status = 1
    //     } else {
    //       this.model.status = 0
    //     }
    //   }
    // },
    methods: {
      getUsuario() {
        try {
          let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
          return user
        } catch (error) {
          console.error(error)
          localStorage.ADMIN_AUTH_USER = null
        }
      },
      back() {
        this.$router.push('/mensagem')
      },
      editar() {
        var vm = this
        this.$swal({
              title: "Alterar Mensagem",
              text: "Tem certeza que deseja Alterar a mensagem em todos os grupos?",
              type: "warning",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Alterar"
          }).then((result) => { 
              if (result.value) {
                this.$aws
                .post('whatsapp/mensagem/alterar', vm.mensagem)
                .then(response => {
                  if (response.status == 200) {
                    this.$swal("Ok!", "Mensagem Alterada.", "success");
                  } else {
                    this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
                  }
                })
                .catch(function (error) {
                  console.error(error)  
                  vm.$swal("Ops!", "Ocorreu algum erro inesperado no envio da mensagem: \n"+ error, "error");
                });   
              }
          });
      },
      excluir() {
        var vm = this

        let body = {
          'msg_id': this.mensagem['id']
        }

        this.$swal({
              title: "Excluir Mensagem",
              text: "Tem certeza que deseja Excluir a mensagem em todos os grupos?",
              type: "warning",
              showCancelButton: true,
              cancelButtonText: "Cancelar",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Excluir"
          }).then((result) => { // <--
              if (result.value) { // <-- if confirmed
                console.log('deletando..')
                console.log(this.mensagem)
                this.$aws
                .post('whatsapp/mensagem/delete', body)
                .then(response => {
                  console.log('response:')
                  console.log(response)
                  if (response.status == 200) {
                    this.mensagem = response.data
                    vm.$swal("Ok!", "Mensagem Excluída.", "success");
                    // this.$router.push('/mensagem')
                   
                  } else {
                    vm.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
                  }
                })
                .catch(function (error) {
                  console.error(error)  
                  vm.$swal("Ops!", "Ocorreu algum erro inesperado no envio da mensagem: \n"+ error, "error");
                });   
              }
          });

          
      },
      tratarTexto(textoOriginal) {
        let retorno = this.decode(textoOriginal)
        retorno = retorno.replace('<p>','')
        retorno = retorno.replace('</p>','')
        retorno = retorno.replace('<strong>','*')
        retorno = retorno.replace('</strong>','*')
        retorno = retorno.replace('<em>','_')
        retorno = retorno.replace('</em>','_')
        return retorno
      },
      decode(encodedString) {
          var textArea = document.createElement('textarea');
          textArea.innerHTML = encodedString;
          return textArea.value;
      },
      getNomeGrupo(grupoId) {
        let retorno = '?'
        for (let i=0; i<this.grupos.length; i++) {
          if (this.grupos[i]['id'] == grupoId) {
            retorno = this.grupos[i]['nome']
          }
        }
        return retorno
      },
      getInstancia(grupoId) {
        let retorno = '?'
        for (let i=0; i<this.grupos.length; i++) {
          if (this.grupos[i]['id'] == grupoId) {
            retorno = this.grupos[i]['instancia_id']
          }
        }
        return retorno
      },
      getTipoGrupo(paramId) {
        let retorno = '?'
        for (let i=0; i<this.tiposGrupos.length; i++) {
          if (this.tiposGrupos[i]['id'] == paramId) {
            retorno = this.tiposGrupos[i]['nome']
          }
        }
        return retorno
      },
      isDeleted() {
        let retorno = false
        if (this.mensagem && this.mensagem.envios) {
          for(let i=0;i<this.mensagem.envios.length; i++) {
            if (this.mensagem.envios[i]['operacao'] == 'DELETE') {
              retorno = true
              break
            }
          }
        }
        return retorno
      },
      isExpired() {
        if (this.isDeleted()) {
          return true
        }

        let dh_atual = new Date()
        for (let i=0; i<this.mensagem.envios.length; i++) {
          let envio = this.mensagem.envios[i]
          let data_envio = new Date(envio['dh_envio'])
          data_envio = this.addMinutes(data_envio, 12)
          if (data_envio > dh_atual) {
            return false
          }
        }
        return true
      },
      addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes*60000);
      }
    },
    mounted() {
      this.$aws
      .get('whatsapp/tipogrupo')
      .then(response => {
        if (response.data) {
          this.tiposGrupos = response.data
        }
      })
      .catch(function (error) {
            console.error(error)
      }); 
      
      let user = this.getUsuario()
      if (user && user.id) {
        if (this.id) {
          this.$aws
          .get('whatsapp/mensagem/'+this.id)
          .then(response => {
            // console.log(response)
            if (response.data) {
              let retorno = JSON.parse(JSON.stringify(response.data))
              this.mensagem = retorno['mensagem']
              this.grupos = retorno['grupos']
              this.instancias = retorno['instancias']
            } else {
              this.model = {}
            }
            $(document).ready( function () {
              $('#tbl-guru').DataTable({
                ordering: false,
                lengthMenu: [50, 100, 300, 500],
                paging: false,
                searching: false,
                "language": {
                  "emptyTable": "Nenhum registro disponível na tabela",
                  "info":           "Total de _TOTAL_ envios",
                  "infoEmpty":      "Nenhum Envio",
                  "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
                  "lengthMenu":     "Mostrar _MENU_ registros",
                  "loadingRecords": "Carregando...",
                  "search":         "Busca:",
                  "zeroRecords":    "Nenhum registro encontrado.",
                  "paginate": {
                      "first":      "Primeiro",
                      "last":       "Último",
                      "next":       "Próximo",
                      "previous":   "Anterior"
                  }
                },
              });
            });
          })
          .catch(function (error) {
                console.error(error)
          });   
        }
      }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
  .button-bar {
    border-top: 2px solid #FC7D59;
    margin-top: 2em;
    padding-top: 2em;
  }
  .tipogrupo {
    background-color: #1E1E2C !important; 
  }
  .ul-grp {
    list-style-type: square;
  }
  #btn-alt {
    pointer-events: auto;
  }
</style>
